import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/main-app/services';
import { PermissionsService } from 'src/app/main-app/services/permissions.service';
import { IMenuVM } from '../../../models/master.model';
import { DataTransferService } from '../../../services/data-transfer.service';
import { EmployeeService } from '../../../services/employee.service';
import { LocalStorageService } from '../../../services/local-storage.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  [x: string]: any;

  profile!: ProfileType;

  menus: IMenuVM[] = [];
  //lbMenuId: number = 0;
  //lbMenus: IMenuVM[] = [];

  menuSubject: Subscription | null = null;
  selectedMenuId: number = 0;
  selectedSubMenuId: number = 0;
  isOnlySingleEdit: boolean = false;
  displayName: string = "";
  currentUserProfile: ICvxClaimsPrincipal | null = null;
  loggedIn: boolean = false;

  constructor(private router: Router,
    private http: HttpClient, //private authService: MsalService,
    private authService: CalAngularService,
    private dataTransferService: DataTransferService,
    private employeeService: EmployeeService,
    private storageService: LocalStorageService,
    private userService: UserService,
    private permissionsService: PermissionsService) {

    this.InitializeSubscription();

  }

  ngOnInit(): void {
    this.authService.isUserSignedIn()
      .subscribe((value: any) => {
        this.loggedIn = value;
        if (this.loggedIn) {
          debugger;
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
          this.displayName = this.currentUserProfile.name;
          this.getUserProfile(this.currentUserProfile.provisioningId);
          this.getSIMTPermissions(this.currentUserProfile.provisioningId);
        }
        else {
          console.log("user not yet signed in");
        }
      })

  }

  getSIMTPermissions(provisioningId: string) {
    this.userService.getUserPermissions(provisioningId).subscribe((response: any) => {
      this.permissionsService.set(response);
    });
  }

  InitializeSubscription() {

    this.menuSubject = this.dataTransferService.triggerMenu
      .subscribe((data: any) => {
        if (data && data.value) {

          let _lbMenus = data.value.filter((e: any) => !e.parentMenuId);
        

          let menuList: IMenuVM[] = [
            { 'menuId': 1, 'name': 'Home', 'urlRoute': '', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 2, 'name': 'New Opportunity', 'urlRoute': 'new-opportunity', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 3, 'name': 'Describe', 'urlRoute': 'describe', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 4, 'name': 'Validate', 'urlRoute': 'validate', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 5, 'name': 'Assurance Status', 'urlRoute': 'assurance-status', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 6, 'name': 'Lookback', 'urlRoute': 'lookback', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': _lbMenus },
            { 'menuId': 7, 'name': 'Reports', 'urlRoute': 'reports', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 8, 'name': 'Admin', 'urlRoute': 'admin', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null },
            { 'menuId': 9, 'name': 'Help', 'urlRoute': 'help', 'applicationId': 1, 'onlySingleEdit': false, 'subMenus': null }

          ];
       
          this.menus = menuList;
          this.MenuDefault();
        }
      }, (err: any) => {
        //print error
      });
  }

  ngOnDestroy(): void {
    this.menuSubject?.unsubscribe();
  }

  MenuDefault() {

    if (this.router.routerState.snapshot.url) {

      let _urlList: string[] = this.router.routerState.snapshot.url.split("/");

      if (_urlList && _urlList.length > 0) {
        let _urlSegment: string[] = _urlList.slice(1);

        if (_urlSegment.length > 0) {
          this.AssignMenu(this.menus, _urlSegment, 1);
        }

      }

    }

  }

  AssignMenu(menus: IMenuVM[], urlSegment: string[], menuLevel: number) {
    let _name: string = urlSegment[0];
    let _menu = menus.find(e => e.urlRoute == _name);

    if (_menu) {

      this.isOnlySingleEdit = _menu.onlySingleEdit;

      //Set menu id based on menu level.
      switch (menuLevel) {
        case 1:
          this.selectedMenuId = _menu.menuId;
          break;
        case 2:
          this.selectedSubMenuId = _menu.menuId;
          break;
      }

      //If menu has sub menu then repeat.
      if (urlSegment.length > 1 || _menu.subMenus?.length > 0) {
        this.AssignMenu(_menu.subMenus, urlSegment.splice(1), menuLevel + 1);
      }


    }
  }

  OnNavClick(menuId: number) {
    if ((menuId || menuId == 0) && this.menus) {
      let _selectedMenu: any = this.menus.find((e: any) => e.menuId == menuId);

      if (_selectedMenu) {
        this.selectedMenuId = menuId;
        if (_selectedMenu.subMenus && _selectedMenu.subMenus.length > 0) {
          let _selectedSubMenu = _selectedMenu.subMenus[0];
          this.OnNavSubClick(menuId, _selectedSubMenu.menuId);
        } else {
          this.router.navigate([`/${_selectedMenu.urlRoute}`]);
        }
      }

    }
  }

  OnNavSubClick(menuId: number, subMenuId: number) {
    if ((menuId || menuId == 0) && this.menus) {
      let _selectedMenu: any = this.menus.find((e: any) => e.menuId == menuId);

      if (_selectedMenu) {
        let _selectedSubMenu: any = _selectedMenu.subMenus.find((e: any) => e.menuId == subMenuId);

        if (_selectedSubMenu) {
          this.selectedMenuId = menuId;
          this.selectedSubMenuId = subMenuId;

          this.router.navigate([`/${_selectedMenu.urlRoute}/${_selectedSubMenu.urlRoute}`]);
        }
      }

    }
  }

  

  getUserProfile(emailId?: string) {
    if (emailId) {
      this.employeeService.getUserProfile(emailId)
        .subscribe((res) => {
          this.storageService.clearUserProfile();
          if (res) {
            this.storageService.saveUserProfile(res);
          }
        }, _error => {
          //print error
        });
    }

  }
}



